<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    :max-width="maxWidth"
    :min-width="minWidth"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
        :disabled="disabled"
        icon
      >
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card v-if="page != null">
      <v-toolbar color="primary" dark flat dense>
        {{ page.title }}
      </v-toolbar>

      <v-card-text class="my-4">
        <wiki-content
          :slug="currentSlug"
          :hash="hash"
          :content="page.rendered"
          :base-url="baseURL"
          @loadSlug="loadSlug"
          :key="counter"
        />
      </v-card-text>

      <v-card-subtitle>{{ $t("related topics") }}</v-card-subtitle>
      <v-card-actions class="justify-center primary lighten-1">
        <v-chip-group v-model="index" column>
          <v-btn
            v-for="(rel, slug) in related"
            :key="slug"
            class="primaryText--text"
            text
            small
            link
            @click="history = [...history, slug]"
          >
            <v-icon left dense> mdi-link </v-icon>
            {{ rel.title }}
          </v-btn>
        </v-chip-group>
      </v-card-actions>

      <v-card-actions class="justify-end">
        <v-btn @click="history = [slug]" :disabled="history.length <= 1" text>
          {{ $t("start") }}
        </v-btn>
        <v-btn
          @click="
            history.splice(-1, 1);
            history = [...history];
          "
          :disabled="history.length <= 1"
          text
        >
          {{ $t("back") }}
        </v-btn>
        <v-spacer />
        <v-btn @click="dialog = false" text>
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import WikiContent from "@/components/basics/WikiContent";

export default {
  name: "Wiki",
  components: {
    WikiContent,
  },
  props: {
    slug: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    color: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    maxWidth: {
      type: String,
      default: "70%",
    },
    minWidth: {
      type: String,
      default: "50%",
    },
  },
  data: () => ({
    dialog: false,
    disabled: true,
    hash: "",
    page: null,
    index: 0,
    loadingPage: false,
    loadingRelated: false,
    history: [],
    related: {},
    counter: 1,
  }),
  computed: {
    baseURL() {
      return this.$config.backendUrl.replace(/api\/$/gm, "wiki/");
    },
    loading() {
      return this.loadingPage || this.loadingRelated;
    },
    defaultSlug() {
      return this.slug || "index";
    },
    currentSlug() {
      if (this.history.length > 0) return this.history[this.history.length - 1];
    },
  },
  watch: {
    history() {
      if (this.history.length > 0) this.getPage();
    },
  },
  methods: {
    getPage() {
      var that = this;
      this.loadingPage = true;
      return this.$http
        .get("pages/" + this.history[this.history.length - 1], {
          disableDefaultErrorHandler: true,
          baseURL: this.baseURL,
        })
        .then((response) => {
          that.page = response.data;
          that.disabled = false;

          // get all related pages
          that.loadingRelated = true;
          that.related = {};
          var requests = [];
          that.page.related.forEach(function (rel) {
            requests.push(that.getRelated(rel.tag));
          });
          axios
            .all(requests)
            .then(
              axios.spread((...responses) => {
                var related = {};
                responses.forEach(function (response) {
                  if (response.status == 200) {
                    var rel = {};
                    response.data.forEach(function (r) {
                      if (r.slug != that.history[that.history.length - 1])
                        rel[r.slug] = r;
                    });
                    related = {
                      ...rel,
                      ...related,
                    };
                  }
                });
                that.related = { ...related };
              })
            )
            .catch((errors) => {
              console.log(">>> error: ", errors);
            })
            .finally(function () {
              that.loadingRelated = false;
            });
        })
        .catch(function (error) {
          that.disabled = true;
          that.history.pop();
        })
        .finally(function () {
          that.loadingPage = false;
          that.counter = that.counter + 1;
        });
    },
    getRelated(tag) {
      return this.$http.get("pages", {
        disableDefaultErrorHandler: true,
        baseURL: this.baseURL,
        params: { tag: tag },
      });
    },
    loadSlug(slug) {
      this.hash = slug.hash;
      this.history = [...this.history, slug.slug];
    },
  },
  mounted() {
    this.loadSlug({
      slug: this.defaultSlug,
      hash: this.$route.hash,
    });
  },
};
</script>

<i18n>
  {
    "en": {
      "start": "Start",
      "back": "Back",
      "close": "Close",
      "not found": "Not found",
      "notFoundMsg": "Page is still under constuction!",
      "related topics": "Related topics"
    }, 
    "de": {
      "start": "Start",
      "back": "Zurück",
      "close": "Schliessen",
      "not found": "Nicht gefunden",
      "notFoundMsg": "Seite ist in Bearbeitung!",
      "related topics": "Verwante Themen"
    }
  }
  </i18n>
